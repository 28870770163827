
















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { PostageCustomerProperties } from "./PostageCustomerView.vue";
import DatePickerInputView from "@/components/shared/DatePickerInputView.vue";
import SpreadsheetService from "@/services/SpreadsheetService";
import SystemSettingService from "@/services/SystemSettingService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import StringUtil from "@/utilities/StringUtil";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const PostageCustomerView = () => import('./PostageCustomerView.vue');

@Component({
    components: { DatePickerInputView, AlertDialogView, PostageCustomerView },
    data: () => ({
        orderNo: "",
        orderDateFrom: null,
        orderDateTo: null,
        customers: [],
        status: [],
        dateFormat: ""
    })
})
export default class PostageView extends Vue {
    private postageCustomer = new PostageCustomerProperties();
    private alertDialog = new AlertDialogProperties();
    private spreadsheetService = new SpreadsheetService();
    private systemSettingService = new SystemSettingService();

    public get statusOptions() {
        return [
            {
                text: this.$t('text.draft'),
                value: "Draft"
            },
            {
                text: this.$t('text.to-pay'),
                value: "To-Pay"
            },
            {
                text: this.$t('text.checkout'),
                value: "Checkout"
            },
            {
                text: this.$t('text.paid'),
                value: "Paid"
            },
            {
                text: this.$t('text.approved'),
                value: "Approved"
            },
            {
                text: this.$t('text.delivered'),
                value: "Delivered"
            },
            {
                text: this.$t('text.cancelled'),
                value: "Cancelled"
            },
            {
                text: this.$t('text.expired'),
                value: "Expired"
            },
            {
                text: this.$t('text.rejected'),
                value: "Rejected"
            },
            {
                text: this.$t('text.transferring'),
                value: "Transferring"
            }
        ];
    }

    public get statusAll() {
        const status: string[] = this.$data.status;
        const options = this.statusOptions;
        return status.length === options.length;
    }

    public set statusAll(v: boolean) {
        this.$data.status = v ? this.statusOptions.map(x => x.value) : [];
    }

    public get indeterminate() {
        const status: string[] = this.$data.status;
        const options = this.statusOptions;
        return status.length > 0 && status.length < options.length;
    }

    public get filterCustomers() {
        const length = this.$data.customers.length;
        if (length > 0) {
            return `${length} record(s) selected`;
        } else {
            return "No record selected";
        }
    }

    public created() {
        this.loadSystemSetting();
    }

    public async loadSystemSetting() {
        try {
            const r = await this.systemSettingService.get();
            this.$data.dateFormat = ResponseUtil.getDateFormat(r);
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public generate() {
        const data = this.$data;
        
        const url = this.spreadsheetService.postageUrl("xlsx", {
            orderNo: StringUtil.enclose(data.orderNo, '%', '%'),
            orderDateFrom: data.orderDateFrom,
            orderDateTo: data.orderDateTo,
            customerId: data.customers.map(x => x.id),
            status: data.status
        });
        window.open(url, "_blank");
    }

    public showCustomers() {
        this.postageCustomer.selected = [].concat(this.$data.customers);
        this.postageCustomer.visible = true;
    }

    public submitCustomers() {
        this.$data.customers = this.postageCustomer.selected;
        this.postageCustomer.visible = false;
    }

    public clearedCustomers() {
        this.$data.customers = [];
    }

    public async alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
    }
}
