import AbstractService from "./AbstractService";

export default class SpreadsheetService extends AbstractService {
    private controller = "spreadsheets";

    public postageUrl(format: string, q: any = null) {
        return this.makeUrlWithQueries(`${this.controller}/postage.${format}`, q);
    }

    public shipmentUrl(format: string, q: any = null) {
        return this.makeUrlWithQueries(`${this.controller}/shipment.${format}`, q);
    }

	public liveSessionUrl(format: string, q: any = null) {
		return this.makeUrlWithQueries(`${this.controller}/live-session.${format}`, q);
	}

	public sessionClosingUrl(format: string, q: any = null) {
		return this.makeUrlWithQueries(`${this.controller}/session-closing.${format}`, q);
	}
}