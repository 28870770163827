import AbstractService from "./AbstractService";
import Axios from "axios";

export default class SystemSettingService extends AbstractService {
    private controller = "system-settings";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }
    
    public allowedDeliveryMethods() {
        const url = this.makeUrl(`${this.controller}/allowed-delivery-methods`);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public liveSessionSetting() {
        const url = this.makeUrl(`${this.controller}/live-session-setting`);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public standardProductPrices() {
        const url = this.makeUrl(`${this.controller}/standard-product-prices`);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public facebookUrl() {
        const url = this.makeUrl(`${this.controller}/facebook-url`);
        return Axios.get<any>(url, this.makeConfig(false));        
    }

    public put(systemSetting: any) {
        const d = { systemSetting };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public linkAccount(facebookId: string) {
        const d = { facebookId };
        const url = this.makeUrl(`${this.controller}/link-account`);
        return Axios.post<any>(url, d, this.makeConfig());
    }
}